<i18n>
{
  "ru": {
    "title": "Заказы"
  },
  "he": {
    "title": "הזמנות"
  }
}
</i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-row
      class="mb-5"
      align="end"
    >
      <template v-for="(item, index) in filter">
        <v-col
          cols="4"
          :key="`filter-item-${index}`"
        >
          <template v-if="item.type === 'datePicker'">
            <v-menu
              v-model="item.open"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.value"
                  :label="item.name"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  color="teal"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  @input="changeFilter()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.value"
                no-title
                scrollable
                color="teal"
                range
                @change="item.open = false; changeFilter()"
              >
              </v-date-picker>
            </v-menu>
          </template>

          <template v-else-if="item.type === 'user'">
            <AutocompleteUser
              v-model="item.value"
              :label="item.name"
              :dense="true"
              @input="changeFilter()"
            ></AutocompleteUser>
          </template>

          <template v-else-if="item.type === 'client'">
            <AutocompleteClient
              v-model="item.value"
              :label="item.name"
              :dense="true"
              @input="changeFilter()"
            ></AutocompleteClient>
          </template>

          <template v-else-if="item.type === 'select'">
            <v-select
              v-model="item.value"
              :items="item.list"
              item-text="name"
              item-value="id"
              :label="item.name"
              clearable
              color="teal"
              dense
              @change="changeFilter()"
            ></v-select>
          </template>

          <template v-else-if="item.type === 'delivery'">
            <SelectDelivery
              v-model="item.value"
              :label="item.name"
              dense
              :multiple="item.multiple"
              @input="changeFilter()"
            ></SelectDelivery>
          </template>

          <template v-else-if="item.type === 'receipts'">
            <SelectReceipts
              v-model="item.value"
              :label="item.name"
              dense
              :multiple="item.multiple"
              @input="changeFilter()"
            ></SelectReceipts>
          </template>

          <template v-else-if="item.type === 'payments'">
            <v-select
              v-model="item.value"
              :items="payments"
              item-text="name"
              item-value="id"
              :label="item.name"
              clearable
              color="teal"
              dense
              @change="changeFilter()"
            ></v-select>
          </template>
        </v-col>
      </template>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      item-key="ID"
      class="elevation-1"
      :footer-props="footer"
      :server-items-length="total"
      :page.sync="page"
      :loading="loading"
      show-select
      v-model="selectedOrders"
    >
      <template v-slot:item="{ item, isSelected, select }">
        <OrderTableRow
          :item="item"
          :isSelected="isSelected"
          @changeSelected="select($event)"
        ></OrderTableRow>
      </template>
    </v-data-table>

    <v-row class="mt-3">
      <v-col
        cols="auto"
      >
        <v-btn
          color="teal"
          :dark="selectedOrders.length > 0"
          @click="showSetStatusPaid = true"
          :disabled="selectedOrders.length <= 0"
        >{{ $t('btn.changePayStatus') }}</v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="auto"
      >
        <v-btn
          color="teal"
          dark
          @click="getCsv = true"
        >{{ $t('btn.getCsv') }}</v-btn>
      </v-col>
    </v-row>

    <template v-if="getCsv">
      <GetOrderCsv
        :filter="paramsFilter"
        :limit="limit"
        :total="total"
        @close="getCsv = false"
      ></GetOrderCsv>
    </template>

    <template v-if="showSetStatusPaid">
      <SetStatusPaid
        :items="selectedOrders"
        @close="showSetStatusPaid = false"
      ></SetStatusPaid>
    </template>
  </div>
</template>

<script>
import AutocompleteClient from '@/components/AutocompleteClient.vue';
import AutocompleteUser from '@/components/AutocompleteUser.vue';
import SelectDelivery from '@/components/SelectDelivery.vue';
import SelectReceipts from '@/components/SelectReceipts.vue';
import OrderTableRow from '@/components/OrderTableRow.vue';
import GetOrderCsv from '@/components/GetOrderCsv.vue';
import SetStatusPaid from '@/components/SetStatusPaid.vue';

export default {
  name: 'OrdersList',
  components: {
    AutocompleteClient,
    AutocompleteUser,
    SelectDelivery,
    SelectReceipts,
    OrderTableRow,
    GetOrderCsv,
    SetStatusPaid,
  },
  data: (vm) => ({
    items: [],
    selectedOrders: [],
    filter: [
      {
        name: vm.$t('props.UF_DATE'),
        code: 'UF_DATE',
        value: null,
        type: 'datePicker',
        open: false,
      },
      {
        name: vm.$t('props.UF_CLIENT'),
        code: 'UF_CLIENT',
        value: null,
        type: 'client',
        list: [],
      },
      {
        name: vm.$t('props.UF_STATUS'),
        code: 'UF_STATUS',
        value: null,
        type: 'select',
        list: [
          {
            name: vm.$t('status.orders.inWork'),
            id: 1,
          },
          {
            name: vm.$t('status.orders.contractorsInWorks'),
            id: 5,
          },
          {
            name: vm.$t('status.orders.done'),
            id: 2,
          },
          {
            name: vm.$t('status.orders.completed'),
            id: 3,
          },
          {
            name: vm.$t('status.orders.finished'),
            id: 4,
          },
          {
            name: vm.$t('status.orders.cancelled'),
            id: 6,
          },
        ],
      },
      {
        name: vm.$t('props.UF_USER'),
        code: 'UF_USER',
        value: null,
        type: 'user',
        list: [],
      },
      {
        name: vm.$t('props.UF_RECEIPTS'),
        code: 'UF_RECEIPTS',
        value: null,
        type: 'receipts',
        list: [],
        multiple: true,
      },
      {
        name: vm.$t('props.UF_DELIVERY'),
        code: 'UF_DELIVERY',
        value: null,
        type: 'delivery',
        list: [],
        multiple: true,
      },
      {
        name: vm.$t('props.UF_PAYMENT'),
        code: 'UF_PAYMENT',
        value: null,
        type: 'payments',
        list: [],
      },
      {
        name: vm.$t('props.UF_PAID'),
        code: 'UF_PAID',
        value: null,
        type: 'select',
        list: [
          {
            id: 3,
            name: vm.$t('status.paid.no'),
          },
          {
            id: 4,
            name: vm.$t('status.paid.partly'),
          },
          {
            id: 5,
            name: vm.$t('status.paid.yes'),
          },
          {
            id: 6,
            name: vm.$t('status.paid.noPaid'),
          },
        ],
      },
    ],
    headers: [
      {
        text: '#',
        value: 'ID',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_USER_ALT'),
        value: 'UF_USER',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_DATE'),
        value: 'UF_DATE',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_CLIENT'),
        value: 'UF_CLIENT',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_DELIVERY'),
        value: 'UF_DELIVERY',
        sortable: false,
      },
      // {
      //   text: vm.$t('props.UF_PAYMENT'),
      //   value: 'UF_PAYMENT',
      //   sortable: false,
      // },
      {
        text: vm.$t('props.UF_GROUP'),
        value: 'GOODS_GROUPS_INFO',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_STATUS'),
        value: 'UF_STATUS',
        sortable: false,
      },
      {
        text: '',
        value: 'btn',
        align: 'right',
        sortable: false,
      },
    ],
    footer: {
      'disable-items-per-page': true,
      'items-per-page-options': [50, 100],
    },
    page: 1,
    limit: 50,
    total: 0,
    loading: false,
    getCsv: false,
    showSetStatusPaid: false,
  }),
  watch: {
    routParam() {
      this.setFilterStatus();
      this.setFilterClient();
      this.changeFilter();
    },
    page() {
      this.getOrders();
    },
  },
  computed: {
    status() {
      return this.$store.getters.getStatus;
    },
    payments() {
      return this.$store.getters.getPayments;
    },
    paramsFilter() {
      const params = {};
      const filterProps = this.filter.filter((item) => item.value || item.value === false);

      filterProps.forEach((item) => {
        params[item.code] = item.value;
      });

      return params;
    },
    routParam() {
      return this.$route.params;
    },
    statusRoutParam() {
      return +this.$route.params.status;
    },
    clientRoutParam() {
      return +this.$route.params.client;
    },
  },
  methods: {
    change() {
      this.selectedOrders.map((item) => {
        const fields = item;
        fields.USER_INFO.NAME = 55;
        return fields;
      });
    },
    changeFilter() {
      this.page = 1;
      this.getOrders();
    },
    getOrders() {
      this.loading = true;
      this.selectedOrders = [];

      const params = {
        order: {
          UF_DATE: 'DESC',
          UF_TIME: 'DESC',
          ID: 'DESC',
        },
        filter: this.paramsFilter,
        select: ['*'],
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
      };

      this.$store.dispatch('getOrders', { params }).then((response) => {
        this.items = response.data.data.result;
        this.total = response.data.data.count;

        this.loading = false;
      });
    },
    setFilterStatus() {
      const findProp = this.filter.find((prop) => prop.code === 'UF_STATUS');

      if (findProp) {
        findProp.value = (this.statusRoutParam) ? this.statusRoutParam : null;
      }
    },
    setFilterClient() {
      const findProp = this.filter.find((prop) => prop.code === 'UF_CLIENT');

      if (findProp) {
        findProp.value = (this.clientRoutParam) ? this.clientRoutParam : null;
      }
    },
  },
  created() {
    this.setFilterStatus();
    this.setFilterClient();
    this.getOrders();
  },
};
</script>
