module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения статуса оплаты"])},
        "orderProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка заказов"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["из"])}
      },
      "he": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שינויים במצב התשלום"])},
        "orderProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["עיבוד הזמנות"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["של"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"ru":{"title":"Изменения статуса оплаты","orderProcessing":"Обработка заказов","from":"из"},"he":{"title":"שינויים במצב התשלום","orderProcessing":"עיבוד הזמנות","from":"של"}}')
  delete Component.options._Ctor
  
}
