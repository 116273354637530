<template>
  <tr :class="colorRow">
    <td>
      <v-simple-checkbox
        hide-details
        :ripple="false"
        color="teal"
        :value="isSelected"
        @input="$emit('changeSelected', $event)"
      ></v-simple-checkbox>
    </td>

    <td class="text-start">{{ item.ID }}</td>

    <td class="text-start">{{ item.USER_INFO.NAME }}</td>

    <td class="text-start">{{ item.UF_DATE }} {{ item.UF_TIME }}</td>

    <td class="text-start">{{ item.CLIENT_INFO.UF_NAME }}</td>

    <td class="text-start">{{ item.DELIVERY_INFO.UF_NAME }}
    </td>

    <td class="text-start">
      <template v-if="item.GOODS_GROUPS_INFO">
        {{ item.GOODS_GROUPS_INFO }}
      </template>
      <!-- <template v-if="item.PAYMENT_INFO">
        {{ item.PAYMENT_INFO.UF_NAME }}
      </template> -->
    </td>

    <td class="text-start">
      {{ item.STATUS_INFO.UF_NAME }}

      <template v-if="item.UF_STATUS === 3">
        <div class="text-caption mt-1">
          <template v-if="item.UF_DATE_SEND_MESSAGE !== null">
            {{ $t('lastSmsSend') }}
            <br>{{ item.UF_DATE_SEND_MESSAGE }}
          </template>

          <template v-else>
            {{ $t('lastSmsSendError') }}
          </template>
        </div>
      </template>
    </td>

    <td class="text-right">
      <v-menu
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            color="teal"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon dark>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            :to="`/orders/${item.ID}`"
          >
            <v-list-item-title>
              {{ $t('btn.detail') }}
            </v-list-item-title>
          </v-list-item>

          <template v-if="item.UF_STATUS === 2">
            <v-divider></v-divider>

            <v-list-item
              href="#"
              @click.prevent="setComplited(item)"
            >
              <v-list-item-title>
                {{ $t('btn.completed') }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <template v-else-if="item.UF_STATUS === 3">
            <v-divider></v-divider>

            <v-list-item
              href="#"
              @click.prevent="setOrderStatus(item.ID, 4)"
            >
              <v-list-item-title>
                {{ $t('btn.done') }}
              </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item
              href="#"
              @click.prevent="addSendMessage(item)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('btn.repeatSend') }}

                  <div class="text-caption mt-1">
                    <template v-if="item.UF_DATE_SEND_MESSAGE !== null">
                      {{ $t('lastSmsSend') }}
                      <br>{{ item.UF_DATE_SEND_MESSAGE }}
                    </template>

                    <template v-else>
                      {{ $t('lastSmsSendError') }}
                    </template>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="item.UF_STATUS === 3 || item.UF_STATUS === 4 || item.UF_STATUS === 6">
            <v-divider></v-divider>

            <v-list-item
              href="#"
              @click.prevent="setOrderStatus(item.ID, 1)"
            >
              <v-list-item-title>
                {{ $t('btn.backInWork') }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <template v-if="item.UF_STATUS !== 6">
            <v-divider></v-divider>

            <v-list-item
              href="#"
              @click.prevent="setOrderStatus(item.ID, 6)"
            >
              <v-list-item-title>
                {{ $t('btn.cancel') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'OrderTableRow',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      return this.$store.getters.getStatus;
    },
    itemStatusObj() {
      const find = this.status.find((ob) => ob.id === this.item.UF_STATUS);
      return (find || false);
    },
    colorRow() {
      return (this.itemStatusObj) ? this.itemStatusObj.color : '';
    },
  },
  methods: {
    setOrderStatus(id, status) {
      const params = new FormData();
      const props = {
        UF_STATUS: status,
      };

      params.append('params', JSON.stringify(props));
      params.append('id', id);

      this.$store.dispatch('updateOrder', params).then((response) => {
        const { success } = response.data.data;

        if (success) {
          this.item.UF_STATUS = status;
        }
      });
    },
    setComplited(item) {
      this.addSendMessage(item).then(() => {
        this.setOrderStatus(item.ID, 3);
      });
    },
    addSendMessage(item) {
      return new Promise((resolve) => {
        const params = new FormData();
        const props = {
          UF_TYPE: 12,
          UF_TEXT: this.$t('messageText.completed'),
          UF_CLIENTS: [item.UF_CLIENT],
        };

        params.append('params', JSON.stringify(props));
        params.append('orderId', item.ID);

        this.$store.dispatch('addSendMessages', params).then((response) => {
          const { date } = response.data.data;
          this.item.UF_DATE_SEND_MESSAGE = date;
          resolve();
        });
      });
    },
  },
};
</script>
