<i18n>
{
  "ru": {
    "title": "Изменения статуса оплаты",
    "orderProcessing": "Обработка заказов",
    "from": "из"
  },
  "he": {
    "title": "שינויים במצב התשלום",
    "orderProcessing": "עיבוד הזמנות",
    "from": "של"
  }
}
</i18n>

<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="480"
  >
    <v-card>
      <v-card-title class="text-h5 mb-3">
        {{ $t('title') }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="status"
              :items="statusList"
              item-text="name"
              item-value="id"
              :label="$t('props.UF_PAID')"
              color="teal"
              dense
            ></v-select>
          </v-col>

          <v-col
            cols="auto"
          >
            <v-btn
              color="teal"
              :dark="!!status"
              @click="setOrdersPaidStatus()"
              :loading="loading"
              :disabled="!status"
            >{{ $t('btn.apply') }}</v-btn>
          </v-col>
        </v-row>

        <v-row v-if="loading || countChangeItems === countItems">
          <v-col cols="auto">
            <v-progress-circular
              :indeterminate="countChangeItems != countItems"
              color="teal"
              :value="(!loading) ? 100 : 0"
            ></v-progress-circular>
          </v-col>

          <v-col cols="auto" class="text-h6">
            {{ $t('orderProcessing') }} ({{ countChangeItems }} {{ $t('from') }} {{ countItems }})
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <template>
          <v-btn
            color="teal"
            text
            :disabled="loading"
            @click="$emit('close', false)"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SetStatusPaid',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: (vm) => ({
    dialog: true,
    loading: false,
    status: null,
    countChangeItems: 0,
    statusList: [
      {
        id: 3,
        name: vm.$t('status.paid.no'),
      },
      // {
      //   id: 4,
      //   name: vm.$t('status.paid.partly'),
      // },
      {
        id: 5,
        name: vm.$t('status.paid.yes'),
      },
      {
        id: 6,
        name: vm.$t('status.paid.noPaid'),
      },
    ],
  }),
  watch: {
    countChangeItems(val) {
      if (val === this.countItems) this.loading = false;
    },
  },
  computed: {
    countItems() {
      return this.items.length;
    },
  },
  methods: {
    setOrdersPaidStatus() {
      this.loading = true;
      this.countChangeItems = 0;

      this.items.forEach((item, i) => {
        setTimeout(() => {
          this.setOrderPaidStatus(item);
        }, i * 1000);
      });
    },
    setOrderPaidStatus(item) {
      const itemNew = item;
      let sum = 0;

      if (this.status === 5) {
        sum = item.UF_SUM + item.UF_RECEIPTS_SUM + item.UF_DELIVERY_SUM;
      }

      const params = new FormData();
      const props = {
        UF_PAID: this.status,
        UF_PAID_SUM: sum,
      };

      params.append('params', JSON.stringify(props));
      params.append('id', item.ID);

      this.$store.dispatch('updateOrder', params).then((response) => {
        const { success } = response.data.data;

        if (success) {
          itemNew.UF_PAID = this.status;
          this.countChangeItems += 1;
        }
      });
    },
  },
};
</script>
