<i18n>
{
  "ru": {
    "title": "Выгрузка в файл",
    "createFile": "Создание файла",
    "writeFile": "Запись в файл",
    "from": "из",
    "getLink": "Получение ссылки"
  },
  "he": {
    "title": "העלאה לקובץ",
    "createFile": "יצירת קובץ",
    "writeFile": "כתיבה לקובץ",
    "from": "של",
    "getLink": "קבלת הפניה"
  }
}
</i18n>

<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="480"
  >
    <v-card>
      <v-card-title class="text-h5 mb-3">
        {{ $t('title') }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="auto">
            <v-progress-circular
              :indeterminate="step === 1"
              color="teal"
              value="100"
            ></v-progress-circular>
          </v-col>

          <v-col cols="auto" class="text-h6">
            {{ $t('createFile') }}
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto">
            <v-progress-circular
              :indeterminate="step === 2"
              color="teal"
              :value="(step > 1) ? 100 : 0"
            ></v-progress-circular>
          </v-col>

          <v-col cols="auto" class="text-h6">
            {{ $t('writeFile') }} ({{ page }} {{ $t('from') }} {{ allpage }})
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="auto">
            <v-progress-circular
              :indeterminate="step === 3"
              color="teal"
              value="100"
            ></v-progress-circular>
          </v-col>

          <v-col cols="auto" class="text-h6">
            {{ $t('getLink') }}
          </v-col>
        </v-row>

        <template v-if="step === 4 && linkFile">
          <v-btn
            color="teal"
            dark
            class="mt-5"
            target="_blank"
            :href="linkFile"
          >{{ $t('btn.download') }}</v-btn>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <template v-if="step === 4 && linkFile">
          <v-btn
            color="teal"
            text
            @click="$emit('close', false)"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GetOrderCsv',
  props: {
    filter: {
      type: Object,
      required: true,
      default: () => {},
    },
    limit: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    step: 1,
    dialog: true,
    page: 1,
    fileName: '',
    linkFile: '',
  }),
  computed: {
    allpage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  methods: {
    getCsv() {
      const params = {
        step: this.step,
        page: this.page,
        fileName: this.fileName,
      };

      if (this.step === 2) {
        const orderParams = {
          order: {
            UF_DATE: 'DESC',
            UF_TIME: 'DESC',
            ID: 'DESC',
          },
          filter: this.filter,
          select: ['*'],
          limit: this.limit,
          offset: (this.page - 1) * this.limit,
        };

        params.orderParams = orderParams;

        params.allpage = this.allpage;
      }

      this.$store.dispatch('getCsv', { params }).then((response) => {
        const {
          step,
          page,
          fileName,
          linkFile,
        } = response.data.data;

        this.step = step;
        this.fileName = fileName;
        this.page = page;
        this.linkFile = linkFile;

        if (step !== 4) {
          setTimeout(() => {
            this.getCsv();
          }, 1000);
        }
      });
    },
  },
  created() {
    this.getCsv();
  },
};
</script>
