module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгрузка в файл"])},
        "createFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание файла"])},
        "writeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запись в файл"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["из"])},
        "getLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получение ссылки"])}
      },
      "he": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["העלאה לקובץ"])},
        "createFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["יצירת קובץ"])},
        "writeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["כתיבה לקובץ"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["של"])},
        "getLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבלת הפניה"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"ru":{"title":"Выгрузка в файл","createFile":"Создание файла","writeFile":"Запись в файл","from":"из","getLink":"Получение ссылки"},"he":{"title":"העלאה לקובץ","createFile":"יצירת קובץ","writeFile":"כתיבה לקובץ","from":"של","getLink":"קבלת הפניה"}}')
  delete Component.options._Ctor
  
}
